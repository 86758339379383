import { default as abuseReportsIEu5x3sS2iMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_9356K4XKyp4qMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexFtSscGhbwUMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/index.vue?macro=true";
import { default as moderationS6zrw6oTCyMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/moderation.vue?macro=true";
import { default as pendingBansocBKWRQwqzMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/pendingBans.vue?macro=true";
import { default as profilesZ0HOV2t9XQMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/profiles.vue?macro=true";
import { default as expensesbQCH580DpwMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexFLgVBp4WksMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewoOjXAvKfRsMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingXSojOU4cJ0Meta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/translations/awaiting.vue?macro=true";
import { default as missing5ZEAs3JS7PMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/translations/missing.vue?macro=true";
import { default as userssFD6HRUwsXMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/admin/users.vue?macro=true";
import { default as apiYLxB4MPSGLMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/api.vue?macro=true";
import { default as _91slug_93muLUlhhIerMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/blog/[slug].vue?macro=true";
import { default as indexSXntEJo9NoMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93H70xWbAZxTMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93YrrtUd4R7EMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminl1UrBRCdirMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/census/admin.vue?macro=true";
import { default as indexcsH8m3yy4YMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/census/index.vue?macro=true";
import { default as contactfmpPT5GqY2Meta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/contact.vue?macro=true";
import { default as designH7nYxMszThMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/design.vue?macro=true";
import { default as englishS4vzcQpf7nMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/english.vue?macro=true";
import { default as faqEVhhphKcBqMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/faq.vue?macro=true";
import { default as inclusiveNJzLXFF1eRMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/inclusive.vue?macro=true";
import { default as indexDreo0910YFMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/index.vue?macro=true";
import { default as licenseT2mwCz3TYZMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/license.vue?macro=true";
import { default as academickYRzH53smbMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/links/academic.vue?macro=true";
import { default as indexg7rvIU9E2DMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/links/index.vue?macro=true";
import { default as mediaGSqO4cGZb4Meta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/links/media.vue?macro=true";
import { default as translinguisticsbff1LW9g2wMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/links/translinguistics.vue?macro=true";
import { default as zinexitejGO8JYMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/links/zine.vue?macro=true";
import { default as namesQgycxAYaFuMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/names.vue?macro=true";
import { default as indexH3RxCxWFQiMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/nouns/index.vue?macro=true";
import { default as templatesBFZ56xgMLYMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/nouns/templates.vue?macro=true";
import { default as peoplep5o691zxVRMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/people.vue?macro=true";
import { default as privacy1LeI4d628IMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/privacy.vue?macro=true";
import { default as _91username_93qWms5sz7MnMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93ee8kTr95qxMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/profile/card-[username].vue?macro=true";
import { default as editoru6qyM4D2WSMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/profile/editor.vue?macro=true";
import { default as anyNUwMQhIAwxMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/any.vue?macro=true";
import { default as askTF60y5reGkMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/ask.vue?macro=true";
import { default as avoidingfPKfvvBDRJMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/avoiding.vue?macro=true";
import { default as indexUgkHXnLefUMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/index.vue?macro=true";
import { default as mirrora2lUWrI99HMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/mirror.vue?macro=true";
import { default as pronounUYftvkwP7NMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesTlgUGfJG7lMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/sources.vue?macro=true";
import { default as team5oWiHDi8fpMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/team.vue?macro=true";
import { default as terminologyMrK09EEF48Meta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/terminology.vue?macro=true";
import { default as termsnDhdpqVjWGMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/terms.vue?macro=true";
import { default as userbENy9DEJloMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/user.vue?macro=true";
import { default as workshops0vIDLpanzHMeta } from "/home/admin/www/pronouns.page/release/20241214173923/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsIEu5x3sS2iMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansocBKWRQwqzMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiYLxB4MPSGLMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93muLUlhhIerMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexSXntEJo9NoMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93H70xWbAZxTMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93YrrtUd4R7EMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminl1UrBRCdirMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexcsH8m3yy4YMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactfmpPT5GqY2Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishS4vzcQpf7nMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqEVhhphKcBqMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveNJzLXFF1eRMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academickYRzH53smbMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexg7rvIU9E2DMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaGSqO4cGZb4Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsbff1LW9g2wMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinexitejGO8JYMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesQgycxAYaFuMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexH3RxCxWFQiMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesBFZ56xgMLYMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peoplep5o691zxVRMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy1LeI4d628IMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93qWms5sz7MnMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93ee8kTr95qxMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editoru6qyM4D2WSMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyNUwMQhIAwxMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askTF60y5reGkMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingfPKfvvBDRJMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexUgkHXnLefUMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrora2lUWrI99HMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounUYftvkwP7NMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesTlgUGfJG7lMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: team5oWiHDi8fpMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyMrK09EEF48Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsnDhdpqVjWGMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userbENy9DEJloMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshops0vIDLpanzHMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241214173923/pages/workshops.vue")
  }
]